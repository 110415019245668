import React from 'react'
const F1 = (props) => (
  <svg
    {...props}
    height="1000"
    viewBox="8.6 3.7 554.8 276.8"
    width="1000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipRule="evenodd" fillRule="evenodd">
      <path
        d="m476 142.4h2.9v-13.4l4.8 13.4h2.5l4.8-13.4v13.4h2.9v-17.3h-4.2l-4.7 13.6-4.8-13.6h-4.2zm-16-14.7h5.5v14.7h3v-14.7h5.5v-2.6h-14zm-35.3 14.7 138.7-138.7h-83.5l-138.7 138.7zm-19.9-78.3h-167.9c-51.2 0-60.1 2.7-82 24.6-20.5 20.5-53.8 53.7-53.8 53.7h72.7l17.4-17.4c11.4-11.4 17.3-12.6 41.2-12.6h124.1zm-252.2 14.7c-15.1 14.3-48.1 46.3-65.8 63.6h-78.2s62.7-62.5 97.5-96.8c35.9-34.1 53.7-41.9 119.6-41.9h239.6l-51.9 51.9h-182.9c-46.2 0-56.6 3.2-77.9 23.2z"
        fill="#fff"
      />
    </g>
  </svg>
)
export default F1
