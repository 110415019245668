/* eslint-disable no-unused-vars */
import {
  firebaseLogin,
  firebaseLogout,
  firebaseRegister,
  firebaseUpdatebyEmail,
} from '@/firebase/utils'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: null,
  isPending: false,
  errorMessage: null,
  isAuthenticated: false,
  isUserPending: true,
  mnemonicPopup: false,
}

export const login = createAsyncThunk(
  'auth/login',
  async ({ request, intl }, thunkAPI) => {
    try {
      const user = await firebaseLogin(request, intl)
      if (user && !user?.error) return user
      else throw user?.error
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  try {
    await firebaseLogout()
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const addUpdateUser = createAsyncThunk(
  'auth/addUpdateUser',
  async (request, thunkAPI) => {
    try {
      const responce = await firebaseUpdatebyEmail(request)
      if (responce && !responce?.error) {
        request.onSuccess && request.onSuccess(responce)
        return responce
      } else throw responce?.error
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message })
    }
  },
)

export const register = createAsyncThunk(
  'user/register',
  async (request, thunkAPI) => {
    try {
      const user = await firebaseRegister(request)
      if (user && !user?.error) {
        return user
      } else throw user?.error
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.user = action.payload
      state.isAuthenticated = true
    },
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload
    },
    setUserBlockList: (state, action) => {
      state.user.blockedUsers = {
        ...action.payload,
      }
    },
    setUserPending: (state, action) => {
      state.isUserPending = action.payload
    },
    setResetAuth: (state) => {
      state.isPending = false
      state.user = null
      state.errorMessage = null
      state.isAuthenticated = false
      state.mnemonicPopup = false
    },
    setMnemonicPopup: (state, action) => {
      state.mnemonicPopup = action.payload
    },
    clearErrorMessage: (state) => {
      state.errorMessage = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.isPending = true
        state.errorMessage = null
      })
      .addCase(login.rejected, (state, action) => {
        state.isPending = false
        state.isUserPending = false
        state.errorMessage = action.payload
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isPending = false
        state.user = action.payload
        state.isUserPending = false
        state.isAuthenticated = true
      })
      .addCase(register.pending, (state, action) => {
        state.isPending = true
        state.errorMessage = null
      })
      .addCase(register.rejected, (state, action) => {
        state.isPending = false
        state.isUserPending = false
        state.errorMessage = action.payload
        state.user = null
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isPending = false
        state.isUserPending = false
        state.isAuthenticated = true
        state.user = action.payload
      })
      .addCase(logout.pending, (state, action) => {
        state.isPending = true
        state.isUserPending = false
        state.errorMessage = null
      })
      .addCase(logout.rejected, (state, action) => {
        state.isPending = false
        state.errorMessage = action.payload
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.user = null
        state.isPending = false
        state.isUserPending = false
        state.errorMessage = null
        state.isAuthenticated = false
      })
      .addCase(addUpdateUser.fulfilled, (state, action) => {
        state.isUserPending = false
        state.user = { ...state.user, ...action.payload.data }
      })
  },
})

export default authSlice.reducer
export const {
  setUserData,
  setAuthenticated,
  setResetAuth,
  clearErrorMessage,
  setMnemonicPopup,
  setUserPending,
  setUserBlockList,
} = authSlice.actions
